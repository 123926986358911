export const CHOICES = {
    INVALID_CHOICE: "INVALID",
    PDS_CHOICE: "PDS",
    LATIF_CHOICE: "LATIF",
    REBEL_CHOICE: "REBEL"
};

export const PDS_IMAGES_LANDSCAPE = [
    '/images/pds/landscape/1.jpg',
    '/images/pds/landscape/2.png',
    '/images/pds/landscape/3.png'
];

export const REBEL_IMAGES_LANDSCAPE = [
    '/images/rebels/landscape/1.jpeg',
    '/images/rebels/landscape/2.jpeg',
    '/images/rebels/landscape/3.jpeg',
    '/images/rebels/landscape/4.png',
    '/images/rebels/landscape/5.png',
    '/images/rebels/landscape/6.jpg',
    '/images/rebels/landscape/7.png'
];

export const PDS_IMAGES_PORTRAIT = [
    '/images/pds/portrait/1.jpg',
    '/images/pds/portrait/2.jpg',
    '/images/pds/portrait/3.jpg'
];

export const REBEL_IMAGES_PORTRAIT = [
    '/images/rebels/portrait/1.jpg',
    '/images/rebels/portrait/2.jpg',
    '/images/rebels/portrait/3.jpg',
    '/images/rebels/portrait/4.jpg',
    '/images/rebels/portrait/5.jpeg'
];

export const ALL_IMAGES_LANDSCAPE = [
    ...PDS_IMAGES_LANDSCAPE,
    ...REBEL_IMAGES_LANDSCAPE
]

export const ALL_IMAGES_PORTRAIT = [
    ...PDS_IMAGES_PORTRAIT,
    ...REBEL_IMAGES_PORTRAIT
]

export const INSTAGRAM_IMAGE = '/images/instagram-white-icon.png'

export const PILOT_RELEASE_DATE = new Date(Date.UTC(2024, 0, 19, 15));

export const PILOT_URL = 'https://youtu.be/xLXvjb1a16o';
