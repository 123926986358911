import React, {useEffect} from "react";
import { PILOT_URL } from "./commonutils";
// import Particles, {initParticlesEngine} from "@tsparticles/react";
// import {loadFull} from "tsparticles";

/**
import particlesOptions from "./particles.json";
import "./App.css";
import Home from "./pages/home/Home";
import { CHOICES } from "./commonutils";

const PDS_PARTICLES = {
    ...particlesOptions, 
    "background": {
        "color": "#0000FF"
    }, 
};

const REBEL_PARTICLES = {
    ...particlesOptions, 
    "background": {
        "color": "#FF0000"
    }, 
};
**/

function App() {
    useEffect(() => {
        window.location.replace(PILOT_URL);
    }, [])

    /**
    const [userChoice, setUserChoice] = useState(CHOICES.INVALID_CHOICE);

    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, [init]);
    return (
        <div className="App">
            {init && <Particles 
                options={
                    userChoice === CHOICES.INVALID_CHOICE 
                    ? particlesOptions 
                    : (userChoice === CHOICES.PDS_CHOICE ? PDS_PARTICLES : REBEL_PARTICLES)}/>}
            <Home userChoice={userChoice} setUserChoice={setUserChoice}/>
        </div>
    );

    **/

    return <></>
}

export default App;
